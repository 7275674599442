import { FaLinkedinIn } from "react-icons/fa";

export default function Contact() {
  return (
    <>
      {/* Contact */}
      <section
        className="contact-section bg-black d-flex align-items-center"
        id="contact"
      // style={{ height: "100vh" }}
      >
        <div className="container">
          <div className="row gx-4 gx-lg-5">
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Address</h4>
                  <hr className="my-4 mx-auto" />
                  {/* <div className="small text-black-50">
                    <a
                      href="https://goo.gl/maps/wMJy7WabuDh3exRV8"
                      target="_blank"
                    >
                      5A, Block A, Sector 80, Noida 201305, India
                    </a>
                  </div> */}
                  {/* <br /> */}
                  <div className="small text-black-50">
                    <a
                      href="https://goo.gl/maps/mkgRymWhZ1WooaDq9"
                      target="_blank"
                    >
                      Floor 12, Brigade Towers, Ashok Nagar, Bangalore 560025,
                      India
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-envelope text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Email</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50">
                    <a href="mailto:info@theskytex.com">info@theskytex.com</a>
                  </div>
                  <br />
                  <div className="small text-black-50">
                    <a href="mailto:careers@theskytex.com">
                      careers@theskytex.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-mobile-alt text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Phone</h4>
                  <hr className="my-4 mx-auto" />
                  <div className="small text-black-50" style={{ display: 'flex' }}>
                    <div style={{ width: '33.33%', borderRight: "1px solid rgb(206, 208, 209)" }}>
                      <p>General Queries </p><a href="tel:+911206857798"> +91 120 685 7798</a>
                      < br />
                      <a href="tel:+919815490918"> +91 9815490918</a>
                    </div>
                    <div style={{ width: '33.33%', borderRight: "1px solid rgb(206, 208, 209)" }}>
                      <p>Defence Queries </p>
                      <a href="tel:+917428886673">+91 7428886673</a>
                      <br />
                      <a href="tel:+918448396381">+91 8448396381</a>
                    </div>
                    <div style={{ width: '33.33%' }}>
                      <p>Vendor Queries </p>
                      <a href="tel:+918448396382">+91 8448396382</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="social d-flex justify-content-center">
            <a
              className="mx-2"
              href="https://in.linkedin.com/company/skytexuas"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn color="#fff" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
